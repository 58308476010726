import React, { useContext } from "react"
import { graphql } from "gatsby"
import {
  Flex,
  Box,
  Grid,
  Container,
  Button,
  Text,
  Heading,
} from "@chakra-ui/react"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { LocalizationContext } from "../context/LocalizationContext"

const PostLayout = ({ content }) => {
  let blocks = []
  content.map((block, i) => {
    switch (block.fieldGroupName) {
      case "post_Postbuilder_Content_Hero":
        blocks.push(
          <Img fluid={block.image.localFile.childImageSharp.fluid} key={i} />
        )
        break
      case "post_Postbuilder_Content_Text":
        blocks.push(
          <Container maxWidth="80ch" key={i}>
            <Box
              fontSize={["md", "lg", "xl"]}
              dangerouslySetInnerHTML={{ __html: block.body }}
            />
          </Container>
        )
        break
      case "post_Postbuilder_Content_Gallery":
        let images = []
        if (block.images.length > 0) {
          block.images.map((image) =>
            images.push(
              <Box width="100%">
                <Box
                  as={Img}
                  fluid={image.localFile.childImageSharp.fluid}
                  key={image.id}
                  width="100%"
                  height="100%"
                  alt={image.altText}
                />
                {image.description && (
                  <Box
                    textAlign="center"
                    fontSize="sm"
                    mt={2}
                    dangerouslySetInnerHTML={{ __html: image.description }}
                  />
                )}
              </Box>
            )
          )
          blocks.push(
            <Container maxWidth="5xl">
              <Grid
                my={[8, 16]}
                gridTemplateColumns="repeat(auto-fit, minmax(300px,1fr))"
                justifyItems="center"
                gridGap={[4, null, 8]}
              >
                {images}
              </Grid>
            </Container>
          )
        }
        break
      default:
        break
    }
  })
  return blocks
}

const BlogPostPage = ({ data, pageContext }) => {
  const post = data.wpPost
  const language = pageContext.language
  const { translate } = useContext(LocalizationContext)
  const { excerpt, author, content } = post.postBuilder
  const locale =
    language.toLowerCase() === "en" || language.toLowerCase() === "zh"
      ? "/"
      : `/${language.toLowerCase()}/`

  return (
    <Layout language={language}>
      <SEO title={post.title} lang={language} />

      <Container textAlign="center" maxWidth="5xl" pt={48} pb={16}>
        <Heading as="h1" size="2xl" mb={4}>
          {post.title}
        </Heading>
        {excerpt && (
          <Text my={8} fontSize={["lg", "xl", "2xl"]}>
            {excerpt}
          </Text>
        )}
        {author && (
          <Flex sx={{ justifyContent: "center", mt: 4 }}>
            <Box>
              <strong>{author.firstName}</strong>
              <br />
              {author.description}
            </Box>
          </Flex>
        )}
      </Container>
      {content && <PostLayout content={content} />}
      <Container maxWidth="5xl">
        <Button as={Link} to={`${locale}blog`}>
          &larr; {translate("navigation.blog")}
        </Button>
      </Container>
    </Layout>
  )
}
export default BlogPostPage

export const blogQuery = graphql`
  query($id: String) {
    wpPost(id: { eq: $id }) {
      id
      slug
      title
      language {
        code
      }
      postBuilder {
        author {
          firstName
          description
        }
        excerpt
        content {
          ... on WpPost_Postbuilder_Content_Hero {
            fieldGroupName
            image {
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 60) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
          ... on WpPost_Postbuilder_Content_Text {
            body
            fieldGroupName
          }
          ... on WpPost_Postbuilder_Content_Gallery {
            fieldGroupName
            images {
              id
              altText
              description
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1280, quality: 60) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
          ... on WpPost_Postbuilder_Content_TourList {
            fieldGroupName
          }
        }
      }
    }
  }
`
